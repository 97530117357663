// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyBhOr9Vf58G1HNyPjPM1_Uku79KB3LQ6sM",
  authDomain: "pranavelric.firebaseapp.com",
  projectId: "pranavelric",
  storageBucket: "pranavelric.appspot.com",
  messagingSenderId: "461060802788",
  appId: "1:461060802788:web:1e1df9eb41072dd8bd84f1",
  measurementId: "G-Y8932WB2RD"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default analytics