// theme.js
export const blueTheme = {
    body: "#EDF9FE",
    text: "#001C55",
    highlight: "#A6E1FA",
    dark: "#00072D",
    secondaryText: "#7F8DAA",
    imageHighlight: "#0E6BA8",
    compImgHighlight: "#E6E6E6",
    jacketColor: "#0A2472",
    headerColor: "#0E6BA877",
  };
  
  export const brownTheme = {
    body: "#FFFEFD",
    text: "#5D2A42",
    highlight: "#FFF9EC",
    dark: "#00072D",
    secondaryText: "#8D697A",
    imageHighlight: "#E29F95",
    compImgHighlight: "#E6E6E6",
    jacketColor: "#FB6376",
    headerColor: "#E29F9577",
  };
  
  export const purpleTheme = {
    body: "#F8EFF4",
    text: "#231942",
    highlight: "#E0B1CB",
    dark: "#00072D",
    secondaryText: "#655E7A",
    imageHighlight: "#BE95C4",
    compImgHighlight: "#E6E6E6",
    jacketColor: "#5E548E",
    headerColor: "#BE95C477",
  };
  
  export const yelGreenTheme = {
    body: "#FFFFEB",
    text: "#003F2F",
    highlight: "#dddf00",
    dark: "#00072D",
    secondaryText: "#4CA58F",
    imageHighlight: "#55a630",
    compImgHighlight: "#E6E6E6",
    jacketColor: "#007f5f",
    headerColor: "#55a63077",
  };
  
  export const redTheme = {
    body: "#FFF8E6",
    text: "#6a040f",
    highlight: "#ffba08",
    dark: "#03071e",
    secondaryText: "#964F56",
    imageHighlight: "#dc2f02",
    compImgHighlight: "#E6E6E6",
    jacketColor: "#9d0208",
    headerColor: "#dc2f0277",
  };
  
  export const blackTheme = {
    body: "#E5E5E5",
    text: "#14213d",
    highlight: "#ffffff",
    dark: "#000000",
    secondaryText: "#5A6377",
    imageHighlight: "#fca311",
    compImgHighlight: "#E6E6E6",
    jacketColor: "#8d99ae",
    headerColor: "#fca31177",
  };
  
  export const pinkTheme = {
    body: "#FEE9F2",
    text: "#620E34",
    highlight: "#FBA7CD",
    dark: "#31071A",
    secondaryText: "#ef476f",
    imageHighlight: "#ef476f",
    compImgHighlight: "#E6E6E6",
    jacketColor: "#8d99ae",
    headerColor: "#ef476f77",
  };
  
  export const violetTheme = {
    body: "#F4EEFC",
    text: "#430A58",
    highlight: "#D6BEF4",
    dark: "#21052C",
    secondaryText: "#875599",
    imageHighlight: "#9b5de5",
    compImgHighlight: "#E6E6E6",
    jacketColor: "#763D8B",
    headerColor: "#9b5de577",
  };
  
  export const greenTheme = {
    body: "#E6FAF5",
    text: "#084c61",
    highlight: "#9BEED8",
    dark: "#031E26",
    secondaryText: "#528190",
    imageHighlight: "#07beb8",
    compImgHighlight: "#E6E6E6",
    jacketColor: "#56a3a6",
    headerColor: "#07beb877",
  };
  
  export const orangeTheme = {
    body: "#FFF0EA",
    text: "#99401F",
    highlight: "#FFB59A",
    dark: "#33150A",
    secondaryText: "#CC552A",
    imageHighlight: "#FF6B35",
    compImgHighlight: "#E6E6E6",
    jacketColor: "#d7263d",
    headerColor: "#FF6B3577",
  };


  
export const lightTheme = {
  name: "light",
  body: "#FFFFFF",
  text: "#343434",
  dark: "#000000",
  secondaryText: "#7F8DAA",
  accentColor: "#E3405F",
  accentBright: "#FC1056",
  projectCard: "#DCE4F2",
  skinColor: "#F7B799",
  skinColor2: "#FCB696",
  imageDark: "#dce4f2",
  imageClothes: "#dce4f2",
  avatarMisc: "#e9ecf2",
  avatarShoes: "#ccd2e3",
};

export const darkTheme = {
  name: "dark",
  // body: "#1D1D1D",
  // text: "#FFFFFF",
  dark: "#12192c",
  // secondaryText: "#8D8D8D",
  accentColorTransparent:"#9aaad6",
  accentColor: "#9aaad6",
  accentBright: "#FC1056",
  transparent:"transparent",
  // projectCard: "#292A2D",
  skinColor: "#F7B799",
  skinColor2: "#FCB696",
  imageDark: "#292A2D",
  imageClothes: "#000000",
  avatarMisc: "#212121",
  avatarShoes: "#9aaad6",
};

  
  export const chosenTheme = blueTheme;