import React from 'react';
import Typewriter from 'typewriter-effect';


export default function TypeText() {
  return (
    <Typewriter
    options={{
      strings: [
        'Developer',
        'Software Engineer',
        'Full Stack Developer',
        'Open Source Contributor',
      ],
      autoStart: true,
      loop: true,
      deleteSpeed: 50,
    }}
  />
  )
}
